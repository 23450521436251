<template>
  <div class="error">
    {{ errors }}
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "error"
    },
    errors: {
      type: String,
      default: () => ''
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  color: $danger;
}
</style>
